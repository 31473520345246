import Cookies from 'js-cookie'
import type {
  IpSrcInput,
  LeadSubmissionInput,
  UserEventLeadType,
} from '../../../__generated__/api-types'
import { UserEventType } from '../../../__generated__/api-types'

interface CreateFacebookDataForLeadProps {
  email: string
  leadType?: UserEventLeadType
  listingId: string
  ip?: IpSrcInput | null
  revenue?: number | null
  useGDPR: boolean
}

export const createFacebookDataForLead = (
  props: CreateFacebookDataForLeadProps
): LeadSubmissionInput['facebookData'] => {
  if (props.useGDPR || !props.ip) return undefined

  const queryParams = new URLSearchParams(window.location.search)

  const sessionId = Cookies.get('rp_session')
  const visitId = Cookies.get('rp_visit_id')

  const evTransId =
    sessionId && visitId
      ? `${visitId}.${sessionId}_${props.listingId}`
      : undefined

  return {
    email: props.email,
    evTransId,
    eventType: UserEventType.Lead,
    ipSrc: props.ip,
    leadType: props.leadType,
    listingId: props.listingId,
    listingRevenue: props.revenue ?? undefined,
    testEventId: queryParams.get('testEventId') || undefined,
    url: window.location.href,
    userAgent: window.navigator.userAgent,
  }
}
