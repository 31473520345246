export function getAltText(
  caption?: string | null,
  name?: string | null,
  index?: number | null
) {
  return !caption || /\.(jpg|jpeg|png)$/i.test(caption)
    ? `${name ? `${name} ` : ''}Photo${
        typeof index === 'number' ? ` ${index + 1}` : ''
      }`
    : caption
}
