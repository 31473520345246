import styles from './listing-card-preview-carousel.module.css'
import btnStyles from './listing-card-carousel.module.css'
import React, { useMemo } from 'react'
import { SlideItem } from './listing-card-carousel'
import type { ListingCardCarousel_ListingFragment } from './__generated__/listing-card-carousel.gql'
import { getAltText } from './listing-card.utils'
import { ScrollSnapCarousel } from '../../components/carousel/scroll-snap-carousel'

type CarouselOwnProps = {
  listingId: string
  dataTags?: string
  drag?: boolean
  loop?: boolean
  onLinkClick?: () => void
  urlPathname?: string | null
  isMobile?: boolean
  isLCP?: boolean
}

type Photo = {
  id: string
  caption?: string | null
}

type CarouselProps = Partial<ListingCardCarousel_ListingFragment> &
  CarouselOwnProps

const CARD_PREVIEW_CAROUSEL_STYLES = {
  nextBtn: btnStyles.btnNext,
  prevBtn: btnStyles.btnPrev,
  track: btnStyles.track,
}

export function ListingCardPreviewCarousel({
  listingId,
  photos,
  isUnpaid,
  name,
  urlPathname,
  isMobile,
  isLCP = false,
  onLinkClick,
}: CarouselProps) {
  const slides = useMemo(() => {
    const clearPhotos = photos?.filter((photo) => Boolean(photo?.id)) as Photo[]
    return (
      clearPhotos.map((photo, index) => {
        return (
          <SlideItem
            id={`listing-card-preview-carousel-${listingId}-${photo.id}-${index}`}
            alt={getAltText(photo.caption, name, index)}
            key={photo.id}
            className={styles.photoContainer}
            urlPathname={urlPathname || ''}
            isUnpaid={Boolean(isUnpaid)}
            isMobile={isMobile}
            onLinkClick={onLinkClick}
            isLCP={isLCP && index === 0}
            name={name || ''}
            photo={photo}
            index={index}
            width={270}
            height={180}
          />
        )
      }) || []
    )
  }, [
    listingId,
    isMobile,
    isLCP,
    isUnpaid,
    name,
    onLinkClick,
    photos,
    urlPathname,
  ])

  return (
    <ScrollSnapCarousel
      slidesInView={1}
      styles={CARD_PREVIEW_CAROUSEL_STYLES}
      className={styles.carouselSection}
      name={`${listingId}-listing-card-preview`}
    >
      {slides}
    </ScrollSnapCarousel>
  )
}
