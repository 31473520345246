'use client'
import { yieldOrContinue } from 'main-thread-scheduling'
import clsx from 'clsx'
import type { HTMLAttributes } from 'react'
import {
  Children,
  forwardRef,
  useCallback,
  useId,
  useRef,
  useState,
  useEffect,
} from 'react'
import { ReactComponent as ChevronLeftIcon } from '@brand/icons/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from '@brand/icons/chevron-right.svg'
import baseStyles from './scroll-snap-carousel.module.css'
import { DIRECTION } from './scroll-snap-carousel.const'
import { useRequestData } from '../../features/request-data/pages-router/use-request-data'
import type { CarouselButtonRef } from './scroll-snap-carousel-button'
import { CarouselButton } from './scroll-snap-carousel-button'

export interface ScrollSnapProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  'data-tag_section'?: string
  disableScrollBars?: boolean
  index?: number
  /**
   * whether or not to render only the slides coming
   * from the `slidesInView` prop. if this is false,
   * render *all* the slides instead.
   *
   * @default true
   */
  lazyRenderSlides?: boolean
  name?: string
  /**
   * currentIndex call made after the slide has changed
   * and the current index is calculated
   */
  onSlideChange?: (currentIndex: number) => void
  /**
   * whether or not to display the carousel controls
   *
   * @default true
   */
  shouldDisplayControls?: boolean
  /** purely for lazy loading only, this doesn't affect
   * any other functionality of the carousel
   *
   * @default 1
   */
  slidesInView?: number
  styles?: {
    track?: string
    nextBtn?: string
    prevBtn?: string
  }
}

export const ScrollSnapCarousel = forwardRef<HTMLDivElement, ScrollSnapProps>(
  (props: ScrollSnapProps, ref) => {
    const {
      children,
      onSlideChange,
      className,
      disableScrollBars,
      styles,
      name,
      index,
      shouldDisplayControls = true,
      lazyRenderSlides = true,
      slidesInView = 1,
    } = props

    const { isMobile } = useRequestData()
    const id = useId()
    const [hasInteracted, setHasInteracted] = useState(!lazyRenderSlides)
    /**
     * this determines how many slides to show at a time. the
     * +1 is to account for the next slide loading so we don't
     * get a glitch or hit the end of the carousel since not
     * all slides are loaded at once
     */
    const slidesToShow = slidesInView + 1
    const trackRef = useRef<HTMLDivElement>(null)
    const slideIndexRef = useRef<number[] | null>(null)
    const childrenArr = Children.toArray(children)
    const totalSlides = Children.count(children)
    const slideIndex = Math.min(Math.max(index ?? 0, 0), totalSlides - 1)
    const carouselId = `${id}${name ? `-${name}` : ''}-carousel`
    const nextButtonRef = useRef<CarouselButtonRef>(null)
    const prevButtonRef = useRef<CarouselButtonRef>(null)

    /**
     * load all the slides up to the current index and the additional
     * slides to show
     */
    const firstSlides = lazyRenderSlides
      ? childrenArr.slice(0, slideIndex + slidesToShow)
      : null
    // load the rest of the slides on interaction with the carousel
    const restOfSlides = lazyRenderSlides
      ? childrenArr.slice(slideIndex + slidesToShow, totalSlides)
      : null

    const handleInteraction = useCallback(
      async function handleInteraction(
        e?: React.MouseEvent | React.TouchEvent
      ) {
        if (!hasInteracted) {
          await yieldOrContinue('interactive')

          /**
           * MOBILE DEVICES ONLY
           *
           * allows more important events to be triggered
           * before the touch event triggers loading of all
           * the slides on mobile devices as the additional
           * slides added momentarily lock up the UI on
           * mobile devices when rendered
           */
          if (e && e.type === 'touchstart') {
            setTimeout(function mobileSetHasInteracted() {
              setHasInteracted(true)
            }, 200)
          } else {
            // no reason to set a timeout on desktop
            setHasInteracted(true)
          }
        }
      },
      [hasInteracted]
    )

    useEffect(() => {
      if (
        !trackRef.current ||
        !prevButtonRef.current ||
        !nextButtonRef.current ||
        !shouldDisplayControls
      ) {
        return
      }

      const slides = Array.from(trackRef.current.children)
      const firstSlide = slides[0]
      const lastSlide = slides[slides.length - 1]

      const observer = new IntersectionObserver(
        async (entries) => {
          // this is to keep track of the current slides intersecting
          const current = [] as number[]

          entries.forEach((entry) => {
            if (entry.target === firstSlide) {
              entry.isIntersecting
                ? prevButtonRef.current?.hide()
                : prevButtonRef.current?.show()
            } else if (entry.target === lastSlide) {
              entry.isIntersecting
                ? nextButtonRef.current?.hide()
                : nextButtonRef.current?.show()
            }

            if (entry.isIntersecting) {
              current.push(slides.indexOf(entry.target))
            }
          })

          /**
           * this is mostly because of HMR. don't do anything
           * if there are no intersecting indices because it
           * means there has been no slide change
           *
           * NOTE: this *will* pass this check if the viewport
           * is resized which is probably ok
           */
          if (current.length > 0) {
            /**
             * check the exiting index ref against the just
             * pushed intersecting indices to determine if
             * the slide has changed
             * if it has it wasn't and the current
             * `slideIndexRef` has been set already,
             * call the onSlideChange callback
             */
            if (
              slideIndexRef.current !== null &&
              slideIndexRef.current[0] !== current[0]
            ) {
              await yieldOrContinue('interactive')
              onSlideChange?.(current[0])
            }
            // update the index ref to the current intersecting indices
            slideIndexRef.current = current
          }
        },
        { threshold: 1 }
      )

      // yes, observe *all* the slides so we can determine
      // the current index
      slides.forEach((child) => {
        observer.observe(child)
      })

      return () => {
        slides.forEach((child) => {
          observer.unobserve(child)
        })
      }
    }, [hasInteracted, onSlideChange, shouldDisplayControls])

    /**
     * Handle navigation clicks to move the carousel in the desired direction.
     */
    const handleNavigationClick = useCallback(
      async function handleNavigationClick(direction: DIRECTION) {
        await yieldOrContinue('interactive')

        if (!trackRef.current) return

        /**
         * get ths offsetWidth of the first child slide in order to determine
         * how far to scroll
         */
        const offsetWidth = (trackRef.current.firstChild as HTMLElement)
          .offsetWidth

        trackRef.current.scrollBy({
          left: direction === DIRECTION.PREV ? -offsetWidth : offsetWidth,
          behavior: 'smooth',
        })
      },
      []
    )

    /**
     * this is to handle the case where the index is not 0 and needs to be
     * scrolled to the correct position on mount
     */
    useEffect(() => {
      if (!trackRef?.current || slideIndex === 0) return

      const offsetWidth = (trackRef.current.firstChild as HTMLElement)
        .offsetWidth

      trackRef.current.scrollBy({
        left: offsetWidth * slideIndex,
        // https://github.com/Microsoft/TypeScript/issues/28755
        behavior: 'instant' as ScrollBehavior,
      })
    }, [slideIndex])

    return (
      <div
        id={carouselId}
        className={clsx(baseStyles.carousel, 'carousel', className)}
        data-tid="carousel"
        ref={ref}
        aria-label={props['aria-label']}
        data-tag_section={props['data-tag_section']}
        /** only run this on desktop */
        onMouseOver={
          !isMobile && !hasInteracted ? handleInteraction : undefined
        }
        /** only run this on mobile */
        onTouchStart={
          isMobile && !hasInteracted ? handleInteraction : undefined
        }
      >
        {shouldDisplayControls && totalSlides > 1 && (
          <div className={clsx(baseStyles.controls, 'carousel__controls')}>
            <CarouselButton
              ref={prevButtonRef}
              className={styles?.prevBtn}
              onClick={handleNavigationClick}
              direction={DIRECTION.PREV}
              aria-controls={`${carouselId}-track`}
              aria-label="Previous Slide"
              data-tid="slider-prev"
              data-tag_section="photo_gallery_left_arrow"
            >
              <ChevronLeftIcon />
            </CarouselButton>
            <CarouselButton
              className={styles?.nextBtn}
              ref={nextButtonRef}
              onClick={handleNavigationClick}
              direction={DIRECTION.NEXT}
              aria-controls={`${carouselId}-track`}
              aria-label="Next Slide"
              data-tid="slider-next"
              data-tag_section="photo_gallery_right_arrow"
            >
              <ChevronRightIcon />
            </CarouselButton>
          </div>
        )}
        <div
          id={`${carouselId}-track`}
          className={clsx(
            !disableScrollBars && baseStyles.disableScrollBars,
            baseStyles.track,
            'carousel__track',
            styles?.track
          )}
          data-tid="carousel-track"
          ref={trackRef}
        >
          {lazyRenderSlides ? firstSlides : childrenArr}
          {lazyRenderSlides && hasInteracted ? restOfSlides : null}
        </div>
      </div>
    )
  }
)

ScrollSnapCarousel.displayName = 'ScrollSnapCarousel'
