'use client'

/* eslint-disable react/jsx-no-target-blank */
import { ReactComponent as StarIcon } from '@brand/icons/star.svg'
import {
  noScheduleCapableCTACopy,
  shouldUseEmailLeadFormOnNoScheduleCTA,
} from '@brand/slots/detail/similar-properties-cta.const'
import { useFeatureVariable } from '@rentpath/ab-testing-react'
import clsx from 'clsx'
import { format } from 'date-fns'
import { CloudImage } from '../../components/cloud-image/cloud-image'
import { CTAButtons } from '../cta-buttons/cta-buttons'
import { SCHEDULE_TOUR } from '../cta-buttons/cta-buttons.const'
import { getIsScheduleTourEnabled } from '../tour-wizard/get-is-schedule-tour-enabled'
import { getListingPriceText } from '../listing/get-listing-price-text'
import type { ListingCardPreview_ListingFragment } from './__generated__/listing-card-preview.gql'
import styles from './listing-card-preview.module.css'
import type { CtaButtons_ListingFragment } from '../cta-buttons/__generated__/cta-buttons.gql'
import { useAtom } from 'jotai'
import { useHideRequestTour } from '../../features/detail/use-hide-request-tour'
import { oneClickCookieAtom } from '../one-click-lead/one-click-lead-cookie.store'
import { getDiamondMaxLeadPhoto } from '../home/get-diamond-max-lead-photo'
import { getDiamondMaxPhotoSources } from '../home/get-diamond-max-photo-sources'
import { ListingCardPreviewCarousel } from './listing-card-preview-carousel'
import type { LightDarkVariant } from '../../types'
import { ListingAvailabilityStatus } from '../../__generated__/api-types'
import { SEND_EMAIL_CTA_LABEL } from '@brand/features/cta-buttons/cta-buttons.const'

type ListingCardPreviewOwnProps = {
  availabilityStatus: ListingAvailabilityStatus
  className?: string
  colorScheme: LightDarkVariant
  fontSize: 'small' | 'medium'
  isTodaysPick?: boolean
  showAddress?: boolean
  showRating?: boolean
  showSquareFeet?: boolean
  showUnitsAvailable?: boolean
  showTourCta?: boolean
  openInNewTab?: boolean
  oneClickLeadActive?: boolean
  'data-tag_item'?: string
  contactedOn?: string
  withCarousel?: boolean
  isMobile?: boolean
}

type ListingFragment = Partial<ListingCardPreview_ListingFragment>

type ListingCardPreviewProps = ListingCardPreviewOwnProps & ListingFragment

const cardStyles = {
  dark: styles.cardDark,
  light: styles.cardLight,
}

const infoCardColors = {
  dark: styles.infoCardDark,
  light: styles.infoCardLight,
}

const infoCardText = {
  small: styles.cardTextSmall,
  medium: styles.cardTextMedium,
}

const detailTextStyles = {
  dark: styles.detailTextDark,
  light: styles.detailTextLight,
}

export function getListingCardPreviewCTALabel(
  isScheduleTourEnabled: boolean,
  contactedOn: boolean,
  hideRequestTour: boolean,
  scheduleTourCtaText = SCHEDULE_TOUR
): string {
  if (contactedOn) {
    return 'Check Unit Availability'
  }

  if (isScheduleTourEnabled) {
    return scheduleTourCtaText
  }

  if (hideRequestTour) {
    return SEND_EMAIL_CTA_LABEL
  }

  return noScheduleCapableCTACopy
}

export function ListingCardButton({
  label,
  isScheduleTourEnabled,
  showEmailLeadFormCTA,
  contactedOn,
  openInNewTab,
  oneClickLeadActive,
}: {
  label: string
  isScheduleTourEnabled: boolean
  showEmailLeadFormCTA: boolean
  contactedOn: boolean
  openInNewTab: boolean
  oneClickLeadActive: boolean
}) {
  if (contactedOn) {
    return (
      <CTAButtons.CheckUnitAvailabilityCTA
        variant="primary"
        fluid
        size="lg"
        openInNewTab={openInNewTab}
      />
    )
  }

  if (!isScheduleTourEnabled && showEmailLeadFormCTA && !oneClickLeadActive) {
    return (
      <CTAButtons.EmailCTA
        icon={null}
        variant="primary"
        fluid
        size="lg"
        label={!oneClickLeadActive ? label : ''}
      />
    )
  }

  return (
    <>
      <CTAButtons.TourCTA
        icon={null}
        variant="primary"
        fluid
        size="lg"
        label={!oneClickLeadActive ? label : ''}
      />
    </>
  )
}

export function ListingCardPreview({
  'data-tag_item': dataTagItem,
  className,
  colorScheme = 'dark',
  fontSize = 'medium',
  isTodaysPick = false,
  showAddress,
  showSquareFeet,
  showUnitsAvailable,
  showTourCta,
  openInNewTab = false,
  oneClickLeadActive = false,
  contactedOn,
  diamondMaxPhoto,
  withCarousel,
  isMobile,
  ...listing
}: ListingCardPreviewProps) {
  const photos = listing.optimizedPhotos?.length
    ? listing.optimizedPhotos
    : listing.photos
  const firstPhoto = photos?.[0]

  const [oneClickData] = useAtom(oneClickCookieAtom)

  const hideRequestTour = useHideRequestTour(
    listing.availabilityStatus,
    listing.tourProviderDetails,
    Boolean(listing.requestTourOptOut)
  )

  const scheduleTourCtaText = useFeatureVariable<string>(
    ['tour_headline_copy', 'schedule_tour_cta_text'],
    SCHEDULE_TOUR
  )

  if (!listing.name) {
    return null
  }

  const isScheduleTourEnabled = getIsScheduleTourEnabled(
    listing.tourProviderDetails
  )

  const isAvailabilityUnknown =
    listing.availabilityStatus === ListingAvailabilityStatus.Unknown

  const label = getListingCardPreviewCTALabel(
    isScheduleTourEnabled,
    Boolean(contactedOn),
    hideRequestTour,
    scheduleTourCtaText
  )

  const leadPhoto = isTodaysPick
    ? getDiamondMaxLeadPhoto(diamondMaxPhoto, listing.photos?.[0])
    : firstPhoto

  const diamondMaxPhotoSources = isTodaysPick
    ? getDiamondMaxPhotoSources(diamondMaxPhoto)
    : undefined

  const todaysPickAlt = `${listing.name} property${
    leadPhoto?.caption ? ` ${leadPhoto?.caption}` : ''
  }`

  const priceText = getListingPriceText({
    priceRange: null,
    isBasicListing: listing.isBasic,
    fallbackPriceText: listing.priceText,
    availabilityStatus: listing.availabilityStatus,
  })

  return (
    <div
      className={clsx(
        cardStyles[colorScheme],
        isTodaysPick && styles.todaysPick,
        className
      )}
    >
      {listing.urlPathname && (
        <a
          data-tid="pdp-link"
          href={listing.urlPathname}
          className={styles.link}
          data-tag_item={dataTagItem || listing.id}
          target={openInNewTab ? '_blank' : '_self'}
          rel={openInNewTab ? 'noreferrer' : undefined}
          aria-label={listing.name}
        />
      )}

      {isTodaysPick && (
        <div className={styles.todaysPickText}>
          <StarIcon /> Today’s Top Pick
        </div>
      )}

      <div className={styles.photoWrapper}>
        {contactedOn && (
          <span className={styles.contactedOn}>
            Contacted on {format(new Date(contactedOn), 'M/d')}
          </span>
        )}
        {photos?.length && photos.length > 1 && withCarousel ? (
          <ListingCardPreviewCarousel
            listingId={listing.id || ''}
            isUnpaid={listing.isUnpaid}
            photos={photos}
            isMobile={isMobile}
            name={listing.name}
            loop
            drag={false}
            urlPathname={listing.urlPathname}
          />
        ) : (
          <CloudImage
            width={270}
            height={180}
            className={styles.photo}
            publicId={leadPhoto?.id ?? ''}
            // On occasion, the images for smaller viewport sizes (sm and md) will be faulty.
            // In that scenario, use the lg (default) image as a fallback.
            // https://rentgroup.atlassian.net/browse/WEB-14701
            publicIdError={diamondMaxPhoto?.lg?.id}
            size="md"
            sizeDesktop="lg"
            isUnpaid={listing.isUnpaid}
            sources={diamondMaxPhotoSources}
            alt={todaysPickAlt}
            errorAlt={todaysPickAlt}
          />
        )}
      </div>
      <div
        className={clsx(
          [infoCardColors[colorScheme]],
          [infoCardText[fontSize]]
        )}
      >
        <div>
          <div className={styles.infoCardFirstLine}>
            <p className={styles.price}>{priceText}</p>
          </div>
          <p className={styles.name}>{listing.name}</p>
          {showAddress && (
            <p className={detailTextStyles[colorScheme]}>
              {listing.addressFull}
            </p>
          )}
          <p className={detailTextStyles[colorScheme]}>
            {[listing.bedText, listing.bathText].filter(Boolean).join(' • ') ||
              '\u00a0'}
          </p>
          {showSquareFeet && (
            <p className={detailTextStyles[colorScheme]}>
              {listing.squareFeetText}
            </p>
          )}
          {showUnitsAvailable && (
            <p className={detailTextStyles[colorScheme]}>
              {listing.unitsAvailableText}
            </p>
          )}
        </div>
        {showTourCta && !listing.isBasic && !isAvailabilityUnknown && (
          <div onClick={contactedOn ? undefined : (e) => e.preventDefault()}>
            <CTAButtons
              className={styles.ctaButtons}
              listing={listing as CtaButtons_ListingFragment}
              oneClickLeadDetails={oneClickData}
              oneClickLeadActive={oneClickLeadActive}
            >
              <ListingCardButton
                label={label}
                isScheduleTourEnabled={
                  isScheduleTourEnabled && !hideRequestTour
                }
                showEmailLeadFormCTA={
                  shouldUseEmailLeadFormOnNoScheduleCTA || hideRequestTour
                }
                contactedOn={Boolean(contactedOn)}
                openInNewTab={openInNewTab}
                oneClickLeadActive={oneClickLeadActive}
              />
            </CTAButtons>
          </div>
        )}
      </div>
    </div>
  )
}
