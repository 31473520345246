import clsx from 'clsx'
import baseStyles from './scroll-snap-carousel.module.css'
import searchResultsStyles from '../../features/search/search-results/search-results.module.css'
import type { DIRECTION } from './scroll-snap-carousel.const'
import type { ForwardedRef, HTMLAttributes } from 'react'
import {
  useState,
  useImperativeHandle,
  useRef,
  forwardRef,
  memo,
  useCallback,
} from 'react'

interface CarouselControlsProps
  extends Omit<HTMLAttributes<HTMLButtonElement>, 'onClick'> {
  direction: DIRECTION.NEXT | DIRECTION.PREV
  onClick?: (direction: DIRECTION) => void
}

export interface CarouselButtonRef extends HTMLButtonElement {
  hide: () => void
  show: () => void
}

export const CarouselButton = memo(
  forwardRef<HTMLButtonElement, CarouselControlsProps>(function CarouselButton(
    props: CarouselControlsProps,
    forwardedRef: ForwardedRef<HTMLButtonElement>
  ) {
    const ref = useRef<HTMLButtonElement>(null)
    const [hide, setHide] = useState(true)
    const { children, className, direction, onClick, ...rest } = props
    const onNavigationClick = useCallback(() => {
      onClick?.(direction)
    }, [onClick, direction])

    useImperativeHandle(
      forwardedRef,
      () => ({
        ...ref.current!,
        hide: () => {
          setHide(true)
        },
        show: () => {
          setHide(false)
        },
      }),
      []
    )

    return (
      <button
        className={clsx(
          baseStyles.control,
          'carousel__control',
          searchResultsStyles.carouselControl,
          className,
          hide && baseStyles.disabled
        )}
        onClick={onNavigationClick}
        {...rest}
        data-tag_cancel_next="scroll"
        type="button"
      >
        {children}
      </button>
    )
  })
)
