'use client'

import { useIsomorphicLayoutEffect } from '@rentpath/react-hooks'
import { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import type { getLocationRelativeToSearchedCity } from '../search/utils/get-location-relative-to-searched-city'
import { useSearchParams } from 'next/navigation'
import { TaggingPageType } from './tagging.const'
import type { TaggingData } from './tagging.types'
import { yieldOrContinue } from 'main-thread-scheduling'

interface UseTrackImpressionOptions {
  itemName?: string
  handleFirstTimeInView?: () => void
  listingIds?: string[]
  listingId?: string
  listingPhotoId?: string
  listingPhotoIndex?: number
  selection?: string
  listingLocationRelativeToSearchedCity?: ReturnType<
    typeof getLocationRelativeToSearchedCity
  >
}

export function useTrackImpression(
  sectionName: string,
  {
    itemName,
    handleFirstTimeInView,
    listingIds,
    listingPhotoId,
    listingPhotoIndex,
    listingId,
    selection,
    listingLocationRelativeToSearchedCity,
  }: UseTrackImpressionOptions = {},
  /**
   * Mostly used for the search page toggling
   * between map and list view
   * we should figure out a better way to handle the toggle
   */
  taggingData?: TaggingData
) {
  const [intersectionRef, inView] = useInView({
    triggerOnce: true,
  })
  const isFirstTimeInView = useRef(true)
  const query = Object.fromEntries(useSearchParams() || [])
  const pageName =
    query?.display === 'map' ? TaggingPageType.SEARCH_MAP : taggingData?.page

  useIsomorphicLayoutEffect(() => {
    async function trackImpression() {
      await yieldOrContinue('idle')
      const impressionProps: Record<string, unknown> = {
        non_interaction: 'true',
        section: sectionName,
        item: itemName || sectionName,
        selection,
        geo_sort_property_match_type: listingLocationRelativeToSearchedCity,
      }

      // only set the page name if we have a display map param
      // or a pageName set in the tagging prop
      if (pageName) {
        impressionProps['page'] = pageName
      }

      if (listingIds) {
        impressionProps['listing_array'] = listingIds.join(',')
      }

      if (listingPhotoId) {
        impressionProps['listing_photo_id'] = listingPhotoId
      }

      if (listingPhotoIndex) {
        impressionProps['listing_photo_index'] = listingPhotoIndex
      }

      if (listingId) {
        impressionProps['listing_id'] = listingId
      }

      if (inView && sectionName !== '') {
        window.eventTracker?.track('impression', impressionProps)
      }
    }

    void trackImpression()
  }, [handleFirstTimeInView, inView, sectionName])

  useEffect(() => {
    async function handleFirstInteraction() {
      await yieldOrContinue('idle')
      if (isFirstTimeInView.current && inView) {
        isFirstTimeInView.current = false
        handleFirstTimeInView?.()
      }
    }
    void handleFirstInteraction()
  }, [inView, handleFirstTimeInView])

  return intersectionRef
}

export function TrackImpression({
  as,
  children,
  options,
  sectionName,
  taggingData,
  ...divAttrs
}: {
  as?: React.ElementType
  sectionName: string
  options?: UseTrackImpressionOptions
  children: React.ReactNode
  taggingData?: TaggingData
} & React.HTMLAttributes<HTMLElement>) {
  const impressionRef = useTrackImpression(sectionName, options, taggingData)
  const Comp = as || 'div'

  return (
    <Comp ref={impressionRef} {...divAttrs}>
      {children}
    </Comp>
  )
}
